@import 'colors.scss';

#landing {
  @media (max-width: 700px) {
    .container {
      margin-top: 0;
    }
  }
  background-color: rgba(255, 255, 255, 0) !important;
  .logo-container {
    margin: 20px auto 15px auto;
    height: 100px;
    //width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo {
    max-width: 320px;
    //height: 72px;
    position: relative;
    top: 0px;
    //animation-name: bounce;
    //animation-duration: 0.9s;
    //animation-iteration-count: infinite;
    //animation-direction: alternate;
    //animation-timing-function: ease-in-out;
  }

  .shadow {
    margin: 15px auto;
    width: 60px;
    height: 10px;
    background: rgba(0, 0, 0, 1);
    opacity: 0.5;
    border-radius: 80%;
    animation-name: shadow;
    animation-duration: 0.9s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
  }

  @keyframes shadow {
    from {
      width: 55px;
      height: 8px;
      opacity: 0.5;
    }
    to {
      width: 30px;
      height: 6px;
      opacity: 0.8;
    }
  }

  @keyframes bounce {
    from {
      top: -15px;
    }
    to {
      top: 0px;
    }
  }

  #hero-screenshot {
    display: block;
    max-width: 80%;
    margin: 64px auto 0 auto;

    @media (max-width: 600px) {
      max-width: 95%;
    }
  }

  form {
    display: flex;
    justify-content: center;
  }

  .feature-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1100px;
    margin: 32px auto;
    padding: 0 1em;

    @media (max-width: 1000px) {
      max-width: 700px;
    }

    .feature-grid-square {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 32%;
      height: 200px;
      box-sizing: border-box;

      @media (max-width: 1000px) {
        max-width: 50%;
      }

      .feature-grid-icon {
        display: flex;
        align-items: flex-end;
        height: 60px;
        img {
          width: 60px;
          object-fit: cover;
        }
      }

      svg {
        margin: 0 auto;
        display: block;
        padding: 2em 0 0 0;
        height: 50px;
        vertical-align: bottom;
      }

      p {
        padding: 0 64px;
        text-align: center;
        font-size: 18px;
        line-height: 24px;
        color: #444;
        font-weight: 400;

        @media (max-width: 1000px) {
          font-size: 18px;
          padding: 0 3em;
        }
        @media (max-width: 600px) {
          padding: 0 1em;
        }
      }

      &:nth-child(1) {
        border-top: 1px solid #fcfcfc;
        border-right: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
        border-left: 1px solid #fcfcfc;

        @media (max-width: 1000px) {
          border-top: 1px solid #fcfcfc;
          border-right: 1px solid #dedede;
          border-bottom: 1px solid #dedede;
          border-left: 1px solid #fcfcfc;
        }
      }
      &:nth-child(2) {
        border-top: 1px solid #fcfcfc;
        border-right: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
        border-left: 1px solid #fcfcfc;

        @media (max-width: 1000px) {
          border-top: 1px solid #fcfcfc;
          border-right: 1px solid #fcfcfc;
          border-bottom: 1px solid #dedede;
          border-left: 1px solid #fcfcfc;
        }
      }
      &:nth-child(3) {
        border-top: 1px solid #fcfcfc;
        border-right: 1px solid #fcfcfc;
        border-bottom: 1px solid #dedede;
        border-left: 1px solid #fcfcfc;
        @media (max-width: 1000px) {
          border-top: 1px solid #fcfcfc;
          border-right: 1px solid #dedede;
          border-bottom: 1px solid #fcfcfc;
          border-left: 1px solid #fcfcfc;
        }
      }
      &:nth-child(4) {
        border-top: 1px solid #fcfcfc;
        border-right: 1px solid #dedede;
        border-bottom: 1px solid #fcfcfc;
        border-left: 1px solid #fcfcfc;
        @media (max-width: 1000px) {
          border-top: 1px solid #fcfcfc;
          border-right: 1px solid #fcfcfc;
          border-bottom: 1px solid #fcfcfc;
          border-left: 1px solid #fcfcfc;
        }
      }
      &:nth-child(5) {
        border-top: 1px solid #fcfcfc;
        border-right: 1px solid #dedede;
        border-bottom: 1px solid #fcfcfc;
        border-left: 1px solid #fcfcfc;
        @media (max-width: 1000px) {
          border-top: 1px solid #dedede;
          border-right: 1px solid #dedede;
          border-bottom: 1px solid #fcfcfc;
          border-left: 1px solid #fcfcfc;
        }
      }
      &:nth-child(6) {
        border-top: 1px solid #fcfcfc;
        border-right: 1px solid #fcfcfc;
        border-bottom: 1px solid #fcfcfc;
        border-left: 1px solid #fcfcfc;

        @media (max-width: 1000px) {
          border-top: 1px solid #dedede;
          border-right: 1px solid #fcfcfc;
          border-bottom: 1px solid #fcfcfc;
          border-left: 1px solid #fcfcfc;
        }
      }
    }
  }

  .testimonials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  .testimonial {
    position: relative;
    width: 25%;
    text-align: left;
    padding: 1.7em 1.3em 1.3em 1.3em;
    background-color: #fff;
    border: 1px solid #ececec;
    color: #343434;
    border-radius: 5px;
    margin: 2em 0.5em;
    line-height: 1.5em;
    height: 100%;

    &.wide {
      font-size: 1.1em;
      width: 80%;
      max-width: 600px;
      margin-bottom: 1em;
    }

    @media (min-width: 900px) and (max-width: 1040px) {
      .hide-on-mobile {
        display: none;
      }
    }

    @media (max-width: 900px) {
      max-width: 600px;
      font-size: 1rem;
      padding: 1.6em 1em 1em 1em;
      margin: 1em 0.5em;
      width: 85%;
    }

    p {
      font-weight: 400;
      font-size: 1em;
      max-width: 100%;
      padding: 0;
      margin: 0 0 0.5em 0;
    }

    .user {
      margin-top: 2.2em;
      display: block;
      text-align: center;
      font-size: 0.75em;
      line-height: 0.5em;
      font-weight: bold;
      color: #bababa;
      text-transform: uppercase;
    }

    .start-quote {
      position: absolute;
      right: calc(50% - 15px);
      top: -16px;
      border: 3px solid #fbb700;
      background-color: #fbb700;
      background-image: url('/assets/images/start-quote-white.svg');
      background-size: 14px 14px;
      background-position: center;
      height: 25px;
      width: 25px;
      border-radius: 15px;
      background-repeat: no-repeat;
      margin-right: 2px;
    }
  }
}

a.read-more-link {
  font-size: 1.1em;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  margin-top: 2em;
  background-color: $primary-color;
  color: #fff;
  border-radius: 3px;
  padding: 10px 12px 7px 12px;
  transition: all 0.2s ease;
  position: relative;
  top: 0;
  &:hover {
    top: -2px;
  }
}

#as-seen-in {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }

  img {
    filter: grayscale(100%) opacity(50%);
    margin: 1em 3em;
    max-width: 180px;
    max-height: 50px;

    &:hover {
      cursor: pointer;
      filter: grayscale(0%) opacity(100%);
    }
  }
}
